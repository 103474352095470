export const environment = {
	production: false,
	webview: true,
	siemens: true,
	amplitude: false,
	FMA: {
		APP_ID: '09FFCE6D-82F4-4E3F-A7F1-A01EA438AB5D',
		AZURE_CID: '9efcf485-8e3d-4cba-bae5-df23b8813bbc',
		PROPERTY_SPECIFIC_CONTENT_KEY: 'SmartWallbox',
		REGION: 'na',
		LANG: 'en_us',
		FMA_URL: 'https://wwwqa.account.ford.com/',
		BASE_URL: '/',
		REDIRECT_URL: 'https://stage.charge-station.ford.com'
	},
	baseUrl: 'https://api.qa01e.gcp.ford.com/api/wallbox/v1',
	tokenAPIUrl: 'https://api.qa01e.gcp.ford.com/api/token/v2/cat-with-refresh-token',
	appId: '09FFCE6D-82F4-4E3F-A7F1-A01EA438AB5D',
	amplitudeApiKey: '6bcd19d1be975e664aa86ce54c9b2a3c',
	vendorCodes: { 'WEB' : ['WS1', 'WEB', 'AUT', 'SIM'], 'SIEMENS' : ['SWA', 'SIE'] },
	vendorIds: { 'WEB': '64f98149ad6e80622b1f5d7f', 'SIE': '64f9816bad6e80622b1f5d80' }
	// include development api base url string here as a property
};
